window.addEventListener("load", () => {
  createAuth0Client({
    domain: "internal.entur.org",
    client_id: "UjTAK3r5m9OyOevDo371EVEIjYczl278",
    redirect_uri: window.location.origin + "/callback",
    audience: "https://plantegning.entur.org",
    max_age: "10000",
  }).then((auth0) => {
    window.auth0 = auth0;
    if (window.location.pathname === "/callback") {
      auth0
        .handleRedirectCallback()
        .then(() => {
          window.location.replace("/");
        })
        .catch(console.error);
    } else {
      auth0.isAuthenticated().then((loggedIn) => {
        if (!loggedIn) {
          auth0.loginWithRedirect().catch(console.error);
        }
      });
    }
  });
});
